import React, { useState } from 'react';
import { TextInput, Textarea, Button, Alert } from '@mantine/core';

const FeedbackForm = () => {
    const [submitted, setSubmitted] = useState(false);
    const formspree_form_id = process.env.REACT_APP_FORMSPREE || "myzydjar";

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent page reload

        const formData = new FormData(e.target); // Capture form data
        const data = Object.fromEntries(formData.entries()); // Convert to an object

        try {
            const response = await fetch(`https://formspree.io/f/${formspree_form_id}`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                setSubmitted(true); // Show thank-you message
                e.target.reset(); // Clear the form
            } else {
                alert('Er ging iets mis. Probeer het opnieuw.');
            }
        } catch (error) {
            console.error('Error submitting the form:', error);
            alert('Er ging iets mis. Probeer het opnieuw.');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            {!submitted ? (
                <>
                    {/* Question 1: Works as Expected */}
                    <TextInput
                        label="Werkt de tool als gewenst?"
                        name="tool_werkt"
                        component="select"
                        placeholder="Selecteer een optie"
                        required
                        mb="sm"
                    >
                        <option value="Ja">Ja</option>
                        <option value="Nee">Nee</option>
                        <option value="Gedeeltelijk">Gedeeltelijk</option>
                    </TextInput>

                    {/* Question 2: Why */}
                    <Textarea
                        label="Waarom?"
                        name="reden"
                        placeholder="Beschrijf waarom de tool wel of niet werkt zoals gewenst"
                        required
                        minRows={4}
                        mb="sm"
                    />

                    {/* Submit Button */}
                    <Button type="submit" fullWidth>
                        Versturen
                    </Button>
                </>
            ) : (
                <Alert title="Dankjewel!" color="green">
                    Dankjewel voor het delen van jouw feedback!
                </Alert>
            )}
        </form>
    );
};

export default FeedbackForm;
