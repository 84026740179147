import React, { useEffect, useState } from 'react';

import {
    MantineProvider,
    Container,
    Stack,
    Paper,
    Text,
    Image,
    Group,
    Box,
    Button,
    Modal,
} from '@mantine/core';
import { InstantSearch, Hits, Stats } from 'react-instantsearch';
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';

import CustomSearchBox from "./CustomSearchBox"
import Hit from "./HitComponent"
import FeedbackForm from './FeedbackForm';

import { initializeAnalytics } from "./analytics";
import { IconMessageCircle } from '@tabler/icons-react';

import '@mantine/core/styles.css';

const { searchClient } = instantMeiliSearch(
    process.env.REACT_APP_MEILI_HTTP_ADDR || 'http://127.0.0.1:7700',
    process.env.REACT_APP_MEILI_MASTER_KEY || '5176z4cqB4CJXS7vn1KOpBrIfN14NMztDy8Eg30DO7M',
    {
        primaryKey: 'id',
    }
);



const customTheme = {
    colors: {
        brand: [
            '#e0f2ff', // Lightest shade
            '#b3d9ff',
            '#80c1ff',
            '#4da9ff',
            '#1a90ff',
            '#0b57d0', // Primary shade
            '#0842a0',
            '#062e70',
            '#041a40',
            '#020e20'  // Darkest shade
        ],
    },
    primaryColor: 'brand',
    defaultRadius: 0,
};

const App = () => {
    const isDevelopment = process.env.REACT_APP_ENV === 'beta'; // Check if the environment is not 'beta'
    if (isDevelopment) document.title = 'DEV-ENVIRONMENT SOOL Zoek-Tool';

    const [modalOpened, setModalOpened] = useState(false);

    const handleModalClose = () => setModalOpened(false);
    const handleModalOpen = () => setModalOpened(true);

    useEffect(() => {
        initializeAnalytics();
    }, []);

    return (
        <MantineProvider theme={customTheme}>
            <Stack style={{height: '100vh', overflow: 'hidden', backgroundColor: customTheme.colors.brand[0]}}>
                <Paper shadow="sm" p="xs"
                       style={{
                           width: '100%',
                           backgroundColor: customTheme.colors.brand[6],
                           color: '#fff'
                       }}>
                    <Group justify="space-between" style={{width: '100%', flexWrap: 'nowrap'}}>
                        <Image src={`${process.env.PUBLIC_URL}/logo_lumc.png`} h={80} fit="contain" alt="LUMC Logo"
                               style={{width: '150px', backgroundColor: customTheme.colors.brand[0]}}/>
                        <Box>
                            <Text mt="lg" align="center" style={{ minWidth: '150px' }}>
                                {isDevelopment ? 'DEV-ENVIRONMENT ' : ''}SOOL Zoek-Tool
                            </Text>
                        </Box>
                        <Box w={'150px'}/>
                    </Group>
                </Paper>

                <Container style={{
                    flexGrow: 1, // Takes up all available space
                    display: 'flex', // Ensures children use flex layout
                    flexDirection: 'column', // Stack children vertically
                    alignItems: 'center', // Center children horizontally
                    overflow: 'auto', // Allows scrolling within the container
                }}>
                    <InstantSearch style={{width: '100%'}} indexName="document-item" searchClient={searchClient}>
                        <CustomSearchBox/>
                        <Hits hitComponent={Hit}/>
                        <Stats/>
                    </InstantSearch>
                </Container>

                <Paper shadow="sm" p="xs"
                       style={{width: '100%', backgroundColor: customTheme.colors.brand[6], color: '#fff'}}>
                    <Text align="right" style={{fontSize: '0.875rem', minWidth: '400px'}}>
                        &copy; {new Date().getFullYear()} Ajar.ai. Alle rechten voorbehouden. |
                        <a href="/privacy-policy" style={{color: '#fff', marginLeft: '5px'}}>Privacy Policy</a>
                    </Text>
                </Paper>

                {/* Floating Feedback Button */}
                <Button
                    style={{
                        position: 'fixed',
                        bottom: '55px',
                        right: '20px',
                        backgroundColor: customTheme.colors.brand[4],
                        color: '#fff',
                        width: '60px', // Equal width and height for a circular shape
                        height: '60px',
                        borderRadius: '50%', // Makes the button round
                        padding: 0,
                    }}
                    radius="xl"
                    size="lg"
                    onClick={handleModalOpen}
                >
                    <IconMessageCircle size={30} /> {/* Icon instead of text */}
                </Button>

                {/* Feedback Modal */}
                <Modal
                    opened={modalOpened}
                    onClose={handleModalClose}
                    title="We waarderen jouw feedback!"
                    overlayOpacity={0.6}
                    overlayBlur={3}
                >
                    <FeedbackForm />
                </Modal>
            </Stack>
        </MantineProvider>
    );
};

export default App;